.city__selector__container {
  width: 80%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media screen and (max-width: 1000px) {
    width: 70%;
  }
}

.city__selector__image__wrapper {
  min-width: 180px;
  margin: 0rem 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 500px) {
    flex-direction: row;
    width: 300px;
    justify-content: space-between;
  }

  img {
    width: 150px;
    cursor: pointer;
    border-radius: 50%;
    -webkit-filter: grayscale(100%);
    transition: all 0.3s ease-in-out;
    filter: grayscale(100%);

    &:hover {
      -webkit-filter: grayscale(0);
      filter: none;
      transform: scale(1.1);
    }

    @media screen and (max-width: 1200px) {
      width: 100px;
    }

    @media screen and (max-width: 500px) {
      width: 75px;
    }
  }

  .active {
    -webkit-filter: grayscale(0);
    filter: none;
  }

  p {
    text-align: center;
    margin-top: 1rem;
    font-weight: bold;
    color: #fff;

    &.active {
      color: #05224e;
    }

    @media screen and (max-width: 500px) {
      margin-left: 1rem;
    }
  }
}
