.loading__spinner__wrapper {
  position: relative;
  height: 100vh;
  width: 100vw;
}

.loading__spinner {
  position: absolute;
  transform: translate(-50%, -30%);
  left: 50%;
  top: 30%;

  @media screen and (max-width: 550px) {
    width: 250px;
  }
}
