.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;
  background-image: linear-gradient(to top, #05224e 0%, #b63778 100%);
}

.main__chart__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.main__chart__wrapper {
  padding: 2rem 1rem 2rem 1rem;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 500px) {
    width: 100%;
  }
}

.main__chart__title__wrapper {
  text-align: center;
  width: 80%;

  @media screen and (max-width: 500px) {
    width: 100%;
  }
}

.main__chart__title {
  color: #05224e;
  margin-bottom: 2rem;

  @media screen and (max-width: 1200px) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: 500px) {
    color: #fff;
  }
}

.main__icons__wrapper {
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
