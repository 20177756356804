*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-family: 'Orbitron', sans-serif;
  scroll-behavior: smooth;
  background-color: #05224e;
}
