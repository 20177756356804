.time__date__wrapper {
  padding: 1rem;
  display: flex;
  justify-content: center;
}

.time__stamp {
  color: #fff;
  font-size: 1.5rem;

  @media screen and (max-width: 500px) {
    font-size: 1rem;
  }
}

.calendar__icon {
  font-size: 2rem;
  color: #fff;
  margin: 0 1rem;

  @media screen and (max-width: 500px) {
    font-size: 1.2rem;
  }
}
