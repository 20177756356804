.title__banner__container {
  padding: 2rem;
  text-align: center;
}

.title {
  color: #05224e;
  font-size: 3rem;

  @media screen and (max-width: 1200px) {
    font-size: 2rem;
  }
}
